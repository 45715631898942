import { Menu } from '../../../components/Sidebar'

export const Dashboard = () => {
  return (
    <div className="flex flex-1">
      {/* <Menu /> */}
      <p>Dashboard</p>
    </div>
  )
}
