import { Routes, Route } from 'react-router-dom'

import { ProtectedRoute } from './ProtectedRoute'
import { Rastreamento } from '../pages/Rastreamento'
import { Relatorio } from '../pages/Relatorio'
import { Dashboard } from '../pages/Middleware/Dashboard'
import { Orders } from '../pages/Middleware/Orders'
import { Login } from '../pages/Middleware/Login'
import { Menu } from '../components/Sidebar'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Rastreamento />} />

      <Route path="/middleware/login" element={<Login />} />

      <Route
        path="/relatorio"
        element={
          <ProtectedRoute>
            <div className="flex">
              <Relatorio />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/middleware"
        element={
          <ProtectedRoute>
            <div className="flex">
              <Menu />
              <Dashboard />
            </div>
          </ProtectedRoute>
        }
      />
      <Route
        path="/middleware/orders"
        element={
          <ProtectedRoute>
            <div className="flex">
              <Menu />
              <Orders />
            </div>
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<Rastreamento />} />
    </Routes>
  )
}
