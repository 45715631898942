import { BrowserRouter } from 'react-router-dom'

import './index.css'
import { AppRoutes } from './routes'
import { AuthProvider } from './context/authContext'

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
