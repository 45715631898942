import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from '../../../context/authContext'

export const Login = () => {
  const navigate = useNavigate()
  const { id, email, token, name, signIn } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = () => {
    signIn({
      email: username,
      password,
    })
  }

  useEffect(() => {
    if (id && email && token && name) {
      navigate('/middleware/orders')
    }
  }, [id, email, token, name, navigate])

  return (
    <div className="flex flex-1 min-h-screen bg-slate-200">
      <div className="w-full max-w-sm p-6 m-auto mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800">
        <div className="flex justify-center mx-auto">
          <img
            // className="w-[336px] h-[70px]"
            src="https://www.sterilybrasil.com/cdn/shop/files/Copia_de_Purewash_150x.png?v=1632764515"
            alt="logo innovat solution"
          />
        </div>

        <form className="mt-6">
          <div>
            <label
              htmlFor="username"
              className="block text-sm text-gray-800 dark:text-gray-200"
            >
              Usuário
            </label>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mt-4">
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm text-gray-800 dark:text-gray-200"
              >
                Senha
              </label>
              <a
                href="/middleware/reset-password"
                className="text-xs text-gray-600 dark:text-gray-400 hover:underline"
              >
                Esqueceu a senha ?
              </a>
            </div>

            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mt-6">
            <button
              type="button"
              onClick={handleLogin}
              className="w-full px-6 py-2.5 text-sm font-medium tracking-wide text-gray-700 bg-blue-400 capitalize transition-colors duration-300 transform bg-cloud rounded-lg hover:text-white hover:bg-blue-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50"
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
