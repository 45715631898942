import React, { createContext, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import api from '../services/api'
import { isAxiosError } from 'axios'

type AuthContextType = {
  id: string | null
  name: string | null
  email: string | null
  token: string | null
  logout: () => void
  signIn: (data: { email: string; password: string }) => void
}

export const AuthContext = createContext<AuthContextType>({
  id: null,
  name: null,
  email: null,
  token: null,
  logout: () => {},
  signIn: () => {},
})

type AuthProviderProps = {
  children: React.ReactNode
}

type SignInData = {
  email: string
  password: string
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate()

  const [id, setId] = useState(() => {
    const id = localStorage.getItem('@SterilyMiddleware:id')

    if (id) {
      return id
    }

    return null
  })

  const [name, setName] = useState(() => {
    const name = localStorage.getItem('@SterilyMiddleware:name')

    if (name) {
      return name
    }

    return null
  })

  const [email, setEmail] = useState(() => {
    const email = localStorage.getItem('@SterilyMiddleware:email')

    if (email) {
      return email
    }

    return null
  })

  const [token, setToken] = useState(() => {
    const token = localStorage.getItem('@SterilyMiddleware:token')

    if (token) {
      return token
    }

    return null
  })

  const logout = useCallback(() => {
    localStorage.clear()
    document.location.reload()
  }, [])

  const signIn = useCallback(
    async ({ email, password }: SignInData) => {
      try {
        const { data } = await api.post('/auth', {
          email,
          password,
        })

        localStorage.setItem('@SterilyMiddleware:id', data.user.id)
        localStorage.setItem('@SterilyMiddleware:name', data.user.name)
        localStorage.setItem('@SterilyMiddleware:email', data.user.email)
        localStorage.setItem('@SterilyMiddleware:token', data.token)

        setId(data.user.id)
        setName(data.user.name)
        setEmail(data.user.email)
        setToken(data.token)

        toast.success('Login efetuado com sucesso !!!')
        navigate('/middleware/orders')
      } catch (error: any) {
        if (isAxiosError(error) && error.response) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.message)
        }
      }
    },
    [navigate],
  )

  return (
    <AuthContext.Provider value={{ id, name, email, token, logout, signIn }}>
      {children}
    </AuthContext.Provider>
  )
}
