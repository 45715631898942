import { useState, useEffect, useCallback } from 'react'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { startOfDay, endOfDay, format, isAfter } from 'date-fns'
import { Truck, ThumbsDown } from 'lucide-react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { ScrollText } from 'lucide-react'
import 'react-datepicker/dist/react-datepicker.css'
import { ptBR } from 'date-fns/locale/pt-BR'
registerLocale('ptBR', ptBR)

type IEvents = {
  [key: string]: string
}

const events: IEvents = {
  'cart.reminder': 'Carrinho abandonado',
  'order.paid': 'Pedido pago',
}

export const Relatorio = () => {
  const [startDate, setStartDate] = useState(startOfDay(new Date()))
  const [endDate, setEndDate] = useState(endOfDay(new Date()))
  const [reportData, setReportData] = useState([])
  const [reportDataList, setReportDataList] = useState([])
  const [detalhar, setDetalhar] = useState(false)

  // const [isLoading, setIsLoading] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false)

  const [personalInfoQuantity, setPersonalInfoQuantity] = useState(0)
  const [paymentInfoQuantity, setPaymentInfoQuantity] = useState(0)
  const [shippingInfoQuantity, setShippingInfoQuantity] = useState(0)

  const getReportList = useCallback(async () => {
    try {
      // if (isAfter(startDate, endDate)) {
      // 	toast.error("Data inicial não pode ser maior que data final");
      // 	return;
      // }

      setIsLoadingList(true)
      setReportDataList([])
      setIsLoadingList(false)

      const response = await api.get('/report/abandoned-list', {
        params: {
          start: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
          end: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
          detalhar,
        },
      })

      setIsLoadingList(true)
      setReportDataList(response.data)
      setIsLoadingList(false)
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      toast.error(error.message)
      setIsLoadingList(false)
    }
  }, [startDate, endDate, detalhar])

  const getReportData = useCallback(async () => {
    try {
      // setIsLoading(true);
      // if (isAfter(startDate, endDate)) {
      // 	toast.error("Data inicial não pode ser maior que data final");
      // 	return;
      // }

      const response = await api.get('/report/abandoned-cart', {
        params: {
          start: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
          end: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
        },
      })

      const personalInfoQuantityTemp = response.data.find(
        (item: { count: number; abandoned_step: string }) =>
          item.abandoned_step === 'personal_info',
      ).count
      setPersonalInfoQuantity(personalInfoQuantityTemp)

      const paymentInfoQuantityTemp = response.data.find(
        (item: { count: number; abandoned_step: string }) =>
          item.abandoned_step === 'payment',
      ).count
      setPaymentInfoQuantity(paymentInfoQuantityTemp)

      const shippingInfoQuantityTemp = response.data.find(
        (item: { count: number; abandoned_step: string }) =>
          item.abandoned_step === 'shippment',
      ).count
      setShippingInfoQuantity(shippingInfoQuantityTemp)

      setReportData(response.data)
      // setIsLoading(false);
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      toast.error(error.message)
      // setIsLoading(false);
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (isAfter(startDate, endDate)) {
      toast.error('Data inicial não pode ser maior que data final')
      return
    }

    getReportData()
    getReportList()
  }, [getReportData, getReportList, startDate, endDate])

  return (
    <div className="flex flex-1 items-center justify-start flex-col font-['Montserrat'] text-sm min-h-[100vh]">
      <div className="container min-w-[100%] flex items-center justify-center bg-[#264365]">
        <span className="text-[17px] pt-[6px] text-[#fff] leading-[26px] font-[400]">
          {' '}
          LIMPEZA INTELIGENTE{' '}
        </span>
      </div>

      <div className="bg-gray-50/50 flex w-[100%]">
        <aside className="bg-gradient-to-br from-gray-800 to-gray-900 -translate-x-80 inset-0 z-50 my-4 ml-4 rounded-xl transition-transform duration-300 xl:translate-x-0 h-[calc(100vh-64px)]">
          <div className="relative border-b border-white/20">
            <a className="flex items-center gap-4 py-6 px-8" href="#/">
              <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white">
                Relatorio Sterily - Yampi
              </h6>
            </a>
            <button
              className="middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs text-white hover:bg-white/10 active:bg-white/30 absolute right-0 top-0 grid rounded-br-none rounded-tl-none xl:hidden"
              type="button"
            >
              <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="h-5 w-5 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          <div className="m-4">
            <ul className="mb-4 flex flex-col gap-1">
              <li>
                <a aria-current="page" className="active" href="/relatorio">
                  <button
                    className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center gap-4 px-4 capitalize"
                    type="button"
                  >
                    {/* <svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="w-5 h-5 text-inherit"
										>
											<path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
											<path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
										</svg> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                      className="w-5 h-5 text-inherit"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 18.375V5.625zM21 9.375A.375.375 0 0020.625 9h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zM10.875 18.75a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5zM3.375 15h7.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375zm0-3.75h7.5a.375.375 0 00.375-.375v-1.5A.375.375 0 0010.875 9h-7.5A.375.375 0 003 9.375v1.5c0 .207.168.375.375.375z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
                      Relatório
                    </p>
                  </button>
                </a>
              </li>

              <li>
                <a aria-current="page" href="/middleware">
                  <button
                    className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white shadow-md hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] w-full flex items-center gap-4 px-4 capitalize hover:bg-blue-500"
                    type="button"
                  >
                    <ScrollText />
                    <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
                      Middleware
                    </p>
                  </button>
                </a>
              </li>
              {/* <li>
								<a className="" href="/relatorio">
									<button
										className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize"
										type="button"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="w-5 h-5 text-inherit"
										>
											<path
												fill-rule="evenodd"
												d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
												clip-rule="evenodd"
											/>
										</svg>
										<p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
											profile
										</p>
									</button>
								</a>
							</li>
							<li>
								<a className="" href="/relatorio">
									<button
										className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize"
										type="button"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="w-5 h-5 text-inherit"
										>
											<path
												fill-rule="evenodd"
												d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 18.375V5.625zM21 9.375A.375.375 0 0020.625 9h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zM10.875 18.75a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5zM3.375 15h7.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375zm0-3.75h7.5a.375.375 0 00.375-.375v-1.5A.375.375 0 0010.875 9h-7.5A.375.375 0 003 9.375v1.5c0 .207.168.375.375.375z"
												clip-rule="evenodd"
											/>
										</svg>
										<p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
											tables
										</p>
									</button>
								</a>
							</li>
							<li>
								<a className="" href="/relatorio">
									<button
										className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize"
										type="button"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="w-5 h-5 text-inherit"
										>
											<path
												fill-rule="evenodd"
												d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
												clip-rule="evenodd"
											/>
										</svg>
										<p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
											notifactions
										</p>
									</button>
								</a>
							</li> */}
            </ul>
            {/* <ul className="mb-4 flex flex-col gap-1">
							<li className="mx-3.5 mt-4 mb-2">
								<p className="block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">
									auth pages
								</p>
							</li>
							<li>
								<a className="" href="/relatorio">
									<button
										className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize"
										type="button"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="w-5 h-5 text-inherit"
										>
											<path
												fill-rule="evenodd"
												d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z"
												clip-rule="evenodd"
											/>
										</svg>
										<p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
											sign in
										</p>
									</button>
								</a>
							</li>
							<li>
								<a className="" href="/relatorio">
									<button
										className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-white hover:bg-white/10 active:bg-white/30 w-full flex items-center gap-4 px-4 capitalize"
										type="button"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="w-5 h-5 text-inherit"
										>
											<path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
										</svg>
										<p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
											sign up
										</p>
									</button>
								</a>
							</li>
						</ul> */}
          </div>
        </aside>
        <div className="p-4 ml-[16px] flex-1">
          <nav className="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
            <div className="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
              <div className="capitalize">
                <nav aria-label="breadcrumb" className="w-max">
                  <ol className="flex flex-wrap items-center w-full bg-opacity-60 rounded-md bg-transparent p-0 transition-all">
                    <li className="flex items-center text-blue-gray-900 antialiased font-sans text-sm font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-light-blue-500">
                      <a href="/relatorio">
                        <p className="block antialiased font-sans text-sm leading-normal text-blue-900 font-normal opacity-50 transition-all hover:text-blue-500 hover:opacity-100">
                          Painel
                        </p>
                      </a>
                      <span className="text-gray-500 text-sm antialiased font-sans font-normal leading-normal mx-2 pointer-events-none select-none">
                        /
                      </span>
                    </li>
                    <li className="flex items-center text-blue-900 antialiased font-sans text-sm font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500">
                      <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">
                        Relatório
                      </p>
                    </li>
                  </ol>
                </nav>
                {/* <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900">
									home
								</h6> */}
              </div>
              {/* <div className="flex items-center">
								<div className="mr-auto md:mr-4 md:w-56">
									<div className="relative w-full min-w-[200px] h-10">
										<input
											className="peer w-full h-full bg-transparent text-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500"
											placeholder=" "
										/>
										<label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal peer-placeholder-shown:text-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-blue-500 before:border-blue-gray-200 peer-focus:before:border-blue-500 after:border-blue-gray-200 peer-focus:after:border-blue-500">
											Type here
										</label>
									</div>
								</div>
								<button
									className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 grid xl:hidden"
									type="button"
								>
									<span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											stroke-width="3"
											className="h-6 w-6 text-blue-gray-500"
										>
											<path
												fill-rule="evenodd"
												d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
												clip-rule="evenodd"
											/>
										</svg>
									</span>
								</button>
								<a href="/relatorio">
									<button
										className="middle none font-sans font-bold center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 hidden items-center gap-1 px-4 xl:flex"
										type="button"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="h-5 w-5 text-blue-gray-500"
										>
											<path
												fill-rule="evenodd"
												d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
												clip-rule="evenodd"
											/>
										</svg>
										Sign In{" "}
									</button>
									<button
										className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 grid xl:hidden"
										type="button"
									>
										<span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												fill="currentColor"
												aria-hidden="true"
												className="h-5 w-5 text-blue-gray-500"
											>
												<path
													fill-rule="evenodd"
													d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
													clip-rule="evenodd"
												/>
											</svg>
										</span>
									</button>
								</a>
								<button
									className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30"
									type="button"
								>
									<span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="h-5 w-5 text-blue-gray-500"
										>
											<path
												fill-rule="evenodd"
												d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
												clip-rule="evenodd"
											/>
										</svg>
									</span>
								</button>
								<button
									aria-expanded="false"
									aria-haspopup="menu"
									id=":r2:"
									className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30"
									type="button"
								>
									<span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="h-5 w-5 text-blue-gray-500"
										>
											<path
												fill-rule="evenodd"
												d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
												clip-rule="evenodd"
											/>
										</svg>
									</span>
								</button>
							</div> */}
            </div>
          </nav>
          <div className="mt-12 mr-[16px]">
            <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
              <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-red-600 to-red-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                  {/* <svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="w-6 h-6 text-white"
										>
											<path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
											<path
												fill-rule="evenodd"
												d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z"
												clip-rule="evenodd"
											/>
											<path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z" />
										</svg> */}
                  <ThumbsDown />
                </div>
                <div className="p-4 text-right">
                  <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">
                    Total Abandonado
                  </p>
                  <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">
                    {reportData?.reduce(
                      (
                        acc: number,
                        item: { abandoned_step: string; count: number },
                      ) =>
                        Number.parseInt(String(acc)) +
                        Number.parseInt(String(item.count)),
                      0,
                    )}
                  </h4>
                </div>
                {/* <div className="border-t border-blue-gray-50 p-4">
									<p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
										<strong className="text-green-500">+55%</strong>&nbsp;than
										last week
									</p>
								</div> */}
              </div>
              <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-pink-600 to-pink-400 text-white shadow-pink-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6 text-white"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div className="p-4 text-right">
                  <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">
                    Personal Info
                  </p>
                  <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">
                    {personalInfoQuantity}
                  </h4>
                </div>
                {/* <div className="border-t border-blue-gray-50 p-4">
									<p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
										<strong className="text-green-500">+3%</strong>&nbsp;than
										last month
									</p>
								</div> */}
              </div>
              <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-green-600 to-green-400 text-white shadow-green-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                  {/* <svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="w-6 h-6 text-white"
										>
											<path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
										</svg> */}
                  <Truck />
                </div>
                <div className="p-4 text-right">
                  <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">
                    Shippment
                  </p>
                  <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">
                    {shippingInfoQuantity}
                  </h4>
                </div>
                {/* <div className="border-t border-blue-gray-50 p-4">
									<p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
										<strong className="text-red-500">-2%</strong>&nbsp;than
										yesterday
									</p>
								</div> */}
              </div>
              <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                  {/* <svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
											className="w-6 h-6 text-white"
										>
											<path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
										</svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6 text-white"
                  >
                    <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z"
                      clip-rule="evenodd"
                    />
                    <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z" />
                  </svg>
                </div>
                <div className="p-4 text-right">
                  <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">
                    Payment
                  </p>
                  <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">
                    {paymentInfoQuantity}
                  </h4>
                </div>
                {/* <div className="border-t border-blue-gray-50 p-4">
									<p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
										<strong className="text-green-500">+5%</strong>&nbsp;than
										yesterday
									</p>
								</div> */}
              </div>
            </div>

            <div className="flex gap-10">
              <div className="flex flex-col w-fit">
                <label htmlFor="start_date">Data Inicial</label>

                <DatePicker
                  className="peer h-full bg-transparent text-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500"
                  id="start_date"
                  showPopperArrow={false}
                  selected={startDate}
                  onChange={(date) => setStartDate(date as Date)}
                  showTimeSelect
                  timeFormat="HH:mm:ss"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="dd-MM-yyyy HH:mm:ss"
                  withPortal
                  locale={ptBR}
                />
                {/* <input
										className="peer h-full bg-transparent text-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500"
										placeholder=" fsdfsd"
										id="start_date"
										type="datetime-local"
									/> */}

                {/* <label className="flex h-full select-none pointer-events-none absolute left-0 font-normal peer-placeholder-shown:text-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400">
										Type here
									</label> */}
              </div>

              <div className="flex flex-col w-fit">
                <label htmlFor="end_date">Data Final</label>
                {/* <input
										className="peer h-full bg-transparent text-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500"
										placeholder=" fsdfsd"
										id="end_date"
										type="datetime-local"
										value={format(endDate, "dd/MM/yyyy HH:mm") || ""}
										onChange={(e) => setEndDate(new Date(e.target.value))}
									/> */}
                <DatePicker
                  className="peer h-full bg-transparent text-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500"
                  id="end_date"
                  showPopperArrow={false}
                  selected={endDate}
                  onChange={(date) => setEndDate(date as Date)}
                  showTimeSelect
                  timeFormat="HH:mm:ss"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="dd-MM-yyyy HH:mm:ss"
                  withPortal
                  locale={ptBR}
                />

                {/* <label className="flex h-full select-none pointer-events-none absolute left-0 font-normal peer-placeholder-shown:text-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400">
										Type here
									</label> */}
              </div>

              <div className="flex flex-col w-fit items-center">
                <label htmlFor="detalhar">Detalhar</label>
                <div className="flex justify-center items-center h-full">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      id="detalhar"
                      type="checkbox"
                      checked={detalhar}
                      className="sr-only peer"
                      onChange={(e) => setDetalhar(!detalhar)}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2 flex-1">
                <div className="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 flex items-center justify-between p-6">
                  <div>
                    <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1">
                      Relatorio de Abandonos
                    </h6>
                    {/* <p className="antialiased font-sans text-sm leading-normal flex items-center gap-1 font-normal text-blue-gray-600">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke-width="3"
													stroke="currentColor"
													aria-hidden="true"
													className="h-4 w-4 text-blue-500"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M4.5 12.75l6 6 9-13.5"
													/>
												</svg>
												<strong>30 done</strong> this month
											</p> */}
                  </div>
                  <button
                    aria-expanded="false"
                    aria-haspopup="menu"
                    id=":r5:"
                    className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30"
                    type="button"
                  >
                    <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currenColor"
                        viewBox="0 0 24 24"
                        stroke-width="3"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
                <div className="p-6 overflow-x-scroll px-0 pt-0 pb-2 w-[100%]">
                  {!isLoadingList && (
                    <table className="w-full min-w-[640px] table-auto">
                      <thead>
                        <tr>
                          <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">
                              Data e Horário
                            </p>
                          </th>
                          <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">
                              Status
                            </p>
                          </th>
                          {detalhar && (
                            <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                              <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">
                                Motivo
                              </p>
                            </th>
                          )}
                          <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">
                              Quantidade
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
                        {reportDataList?.map(
                          (item: {
                            horario: string
                            event: string
                            quantidade: string
                            abandoned_step?: string
                          }) => (
                            <tr key={item.horario}>
                              <td className="py-3 px-6 border-b border-blue-gray-50">
                                <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
                                  {item.horario}
                                </p>
                              </td>
                              <td className="py-3 px-6 border-b border-blue-gray-50">
                                <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
                                  {events[item.event]}
                                </p>
                              </td>
                              {detalhar && (
                                <td className="py-3 px-6 border-b border-blue-gray-50">
                                  <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
                                    {item.abandoned_step}
                                  </p>
                                </td>
                              )}
                              <td className="py-3 px-5 border-b border-blue-gray-50">
                                <div className="w-10/12">
                                  <p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">
                                    {item.quantidade}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ),
                        )}
                        {/* <tr>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="flex items-center gap-4">
															<p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
																Material XD Version
															</p>
														</div>
													</td>

													<td className="py-3 px-5 border-b border-blue-gray-50">
														<p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
															$14,000
														</p>
													</td>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="w-10/12">
															<p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">
																60%
															</p>
															<div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
																<div
																	className="flex justify-center items-center h-full bg-gradient-to-tr from-blue-600 to-blue-400 text-white"
																	style={{ width: "60%" }}
																/>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="flex items-center gap-4">
															<p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
																Add Progress Track
															</p>
														</div>
													</td>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
															$3,000
														</p>
													</td>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="w-10/12">
															<p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">
																10%
															</p>
															<div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
																<div
																	className="flex justify-center items-center h-full bg-gradient-to-tr from-blue-600 to-blue-400 text-white"
																	style={{ width: "10%" }}
																/>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="flex items-center gap-4">
															<p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
																Fix Platform Errors
															</p>
														</div>
													</td>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
															Not set
														</p>
													</td>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="w-10/12">
															<p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">
																100%
															</p>
															<div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
																<div
																	className="flex justify-center items-center h-full bg-gradient-to-tr from-green-600 to-green-400 text-white"
																	style={{ width: "100%" }}
																/>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="flex items-center gap-4">
															<p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
																Launch our Mobile App
															</p>
														</div>
													</td>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
															$20,500
														</p>
													</td>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="w-10/12">
															<p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">
																100%
															</p>
															<div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
																<div
																	className="flex justify-center items-center h-full bg-gradient-to-tr from-green-600 to-green-400 text-white"
																	style={{ width: "100%" }}
																/>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="flex items-center gap-4">
															<p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
																Add the New Pricing Page
															</p>
														</div>
													</td>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">
															$500
														</p>
													</td>
													<td className="py-3 px-5 border-b border-blue-gray-50">
														<div className="w-10/12">
															<p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">
																25%
															</p>
															<div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
																<div
																	className="flex justify-center items-center h-full bg-gradient-to-tr from-blue-600 to-blue-400 text-white"
																	style={{ width: "25%" }}
																/>
															</div>
														</div>
													</td>
												</tr> */}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-blue-gray-600">
						<footer className="py-2">
							<div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 md:justify-between">
								<p className="block antialiased font-sans text-sm leading-normal font-normal text-inherit">
									© 2023, made with{" "}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										aria-hidden="true"
										className="-mt-0.5 inline-block h-3.5 w-3.5"
									>
										<path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
									</svg>{" "}
									by{" "}
									<a
										href="https://www.creative-tim.com"
										target="_blank"
										className="transition-colors hover:text-blue-500"
										rel="noreferrer"
									>
										Creative Tim
									</a>{" "}
									for a better web.{" "}
								</p>
								<ul className="flex items-center gap-4">
									<li>
										<a
											href="https://www.creative-tim.com"
											target="_blank"
											className="block antialiased font-sans text-sm leading-normal py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
											rel="noreferrer"
										>
											Creative Tim
										</a>
									</li>
									<li>
										<a
											href="https://www.creative-tim.com/presentation"
											target="_blank"
											className="block antialiased font-sans text-sm leading-normal py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
											rel="noreferrer"
										>
											About Us
										</a>
									</li>
									<li>
										<a
											href="https://www.creative-tim.com/blog"
											target="_blank"
											className="block antialiased font-sans text-sm leading-normal py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
											rel="noreferrer"
										>
											Blog
										</a>
									</li>
									<li>
										<a
											href="https://www.creative-tim.com/license"
											target="_blank"
											className="block antialiased font-sans text-sm leading-normal py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
											rel="noreferrer"
										>
											License
										</a>
									</li>
								</ul>
							</div>
						</footer>
					</div> */}
        </div>
      </div>
    </div>
  )
}
