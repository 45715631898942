import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from '../context/authContext'

type ProtectedRouteProps = {
  children: React.JSX.Element
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { id, email, token, name } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!id && !email && !token && !name) {
      navigate('/middleware/login')
    }
  }, [navigate, id, email, token, name])

  return children
}
