import axios from 'axios'

const api = axios.create({
  baseURL: '/api/',
  // baseURL: 'http://localhost:3001/api/',
  // headers: {
  //   Authorization: `Bearer ${localStorage.getItem('@SterilyMiddleware:token')}`,
  // },
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { response } = error
    const { status } = response

    if (status === 401) {
      if (response.data.error) {
        if (response.data.error === 'Token invalid') {
          localStorage.clear()
          window.location.reload()
        }
      }
    }
    return Promise.reject(error)
  },
)

export default api
